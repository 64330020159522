:root {
  --primary-color: #00a8e8;
  --secondary-color: #003459;
  --background-color: #f8f9fa;
  --text-color: #333;
  --card-bg-color: #ffffff;
}

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0; /* Reset default padding */
  background-color: #f0f4f8; /* Light background for contrast */
  color: #333; /* Dark text for readability */
  padding: 20px; /* Add padding to the body */
}

header {
  background-color: transparent; /* Make the header background transparent */
  padding: 20px; /* Padding for header */
  box-shadow: none; /* Remove any shadow */
  border: none; /* Ensure no border */
}

.navbar {
  display: flex;
  justify-content: center; /* Center the nav items */
  list-style-type: none;
  padding: 0;
}

.navbar-nav {
  display: flex;
}

.navbar-nav li {
  margin: 0 20px; /* Space between nav items */
}

.navbar-nav a {
  color: white; /* Link color */
  text-decoration: none;
  font-weight: bold; /* Bold links */
  transition: color 0.3s; /* Smooth color transition */
}

.navbar-nav a:hover {
  color: #00a8e8; /* Change color on hover */
}

.hero {
  background: linear-gradient(to right, #00334e, #007bff); /* Gradient background */
  color: white;
  text-align: center;
  padding: 60px 20px; /* Increased padding for hero section */
  border-radius: 15px; /* Add rounded corners */
  box-shadow: none; /* Ensure no shadow */
  margin: 0; /* Remove any margin */
}

.hero h1 {
  margin: 0;
  font-size: 2.5em; /* Larger font size for the title */
}

.hero p {
  font-size: 1.2em; /* Slightly larger font for the subtitle */
}

.cta-button {
  background-color: #00a8e8; /* Button color */
  color: white;
  padding: 12px 24px;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
  display: inline-block; /* Center the button */
  transition: background-color 0.3s; /* Smooth background transition */
}

.cta-button:hover {
  background-color: #007bff; /* Darker blue on hover */
}

/* Main content styles */
main {
  max-width: 1200px; /* Max width for main content */
  margin: 20px auto; /* Center the main content */
  padding: 20px; /* Padding for main content */
  background-color: #ffffff; /* White background for main content */
  border-radius: 8px; /* Rounded corners */
  box-shadow: none; /* Remove shadow for a flat look */
}

/* Event list styles */
.event-list {
  display: flex;
  flex-direction: column; /* Stack events vertically */
  gap: 20px; /* Space between event cards */
}

/* Event card styles */
.event-card {
  background: #f9f9f9; /* Light background for cards */
  border-radius: 8px;
  padding: 20px;
  transition: transform 0.2s; /* Smooth hover effect */
  border: none; /* Remove border */
}

.event-card:hover {
  transform: translateY(-5px); /* Lift effect on hover */
}

/* Heading styles */
h1, h2 {
  margin: 20px 0; /* Margin for headings */
  font-weight: bold; /* Ensure headings are bold */
}

h1 {
  font-size: 2em; /* Size for main headings */
}

h2 {
  font-size: 1.5em; /* Size for subheadings */
  color: #00334e; /* Dark blue for subheadings */
}

/* Organizer and Community Links Styles */
ul {
  padding-left: 20px; /* Indent list items */
  font-family: 'Arial', sans-serif; /* Ensure the same font is used */
  color: #333; /* Dark text for readability */
}

li {
  margin: 10px 0; /* Space between list items */
  font-size: 1em; /* Ensure consistent font size */
}

a {
  color: #007bff; /* Link color */
  text-decoration: none; /* Remove underline */
  transition: color 0.3s, text-decoration 0.3s; /* Smooth transition for hover effects */
}

a:hover {
  color: #0056b3; /* Darker blue on hover */
  text-decoration: underline; /* Underline on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .event-card {
    flex: 1 1 100%; /* Full width on small screens */
  }

  .navbar-nav li {
    margin: 0 10px; /* Adjust spacing for smaller screens */
  }
}

/* Back Link Styles */
.back-link {
    color: #007bff; /* Link color */
    text-decoration: none; /* Remove underline */
    font-size: 1em; /* Font size */
    margin-bottom: 10px; /* Space below the link */
    display: inline-block; /* Make it a block for spacing */
}

.back-link:hover {
    text-decoration: underline; /* Underline on hover */
}
